<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="educationInfo"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-4 col-sm-4 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :editable="editable"
            label="교육과정명"
            name="educationCourseName"
            v-model="educationInfo.educationCourseName">
          </c-text>
        </div>
        <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_FST_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdLarge"
            label="교육종류1"
            v-model="educationInfo.educationKindCdLarge"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_KIND_SEC_CD"
            itemText="codeName"
            itemValue="code"
            name="educationKindCdSmall"
            label="교육종류2"
            v-model="educationInfo.educationKindCdSmall"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            required
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            name="educationTypeCd"
            label="교육구분"
            v-model="educationInfo.educationTypeCd"
          ></c-select>
        </div>
        <div class="col-3">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="법정교육여부"
            name="legalEducationFlag"
            v-model="educationInfo.legalEducationFlag">
          </c-checkbox>
        </div>
        <!-- <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            required
            :editable="editable"
            type="time"
            :minuteStep="10"
            label="교육시간"
            name="educationTime"
            v-model="educationInfo.educationTime"
          />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :editable="editable"
            type="edit"
            codeGroupCd="EDU_CYCLE_CD"
            itemText="codeName"
            itemValue="code"
            name="educationCycle"
            label="교육주기"
            v-model="educationInfo.educationCycle"
          ></c-select>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-textarea
            required
            :editable="editable"
            label="학습목적"
            name="educationPurpose"
            v-model="educationInfo.educationPurpose">
          </c-textarea>
        </div>
        <div class="col-6">
          <c-multi-select
            required
            :editable="editable"
            codeGroupCd="REGULATION_BILL_CD"
            itemText="codeName"
            itemValue="code"
            label="관련 법규"
            name="relationLaws"
            maxValues="3"
            v-model="educationInfo.relationLaws">
          </c-multi-select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :editable="editable"
            label="주요 대상자"
            name="mainTargetAudience"
            v-model="educationInfo.mainTargetAudience">
          </c-text>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-text
            suffix="원"
            type="number"
            :editable="editable"
            label="예상 교육비"
            name="estimatedEducationExpenses"
            v-model="educationInfo.estimatedEducationExpenses">
          </c-text>
        </div>
        <!-- <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant 
            required
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="educationInfo.plantCd" />
        </div> -->
        <div class="col-xs-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
          <c-checkbox
            :editable="editable"
            :isFlag="true"
            label="사용여부"
            name="useFlag"
            v-model="educationInfo.useFlag">
          </c-checkbox>
        </div>
      </template>
    </c-card>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="교육내용"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="educationInfo.eduCourseSubjectList"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        :hideBottom="true"
        :isExcelDown="false"
        noDataLabel="교육내용이 없습니다."
        :editable="editable"
        rowKey="subjectSeq"
        selection="multiple"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" :showLoading="false"  icon="add" @btnClicked="addItem" />
            <c-btn v-if="editable && educationInfo.eduCourseSubjectList.length > 0" label="제외" :showLoading="false" icon="remove" @btnClicked="removeItem" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
  </q-form>
</template>

<script>
import { uid } from "quasar";
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'education-curriculumn-info',
  props: {
    eduCourseId: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      educationInfo: {
        eduCourseId: '',  // 교육과정 일련번호
        plantCd: '',  // 공사현장 코드
        educationCourseName: '',  // 교육과정명
        educationKindCdLarge: null,  // 교육종류1
        educationKindCdSmall: null,  // 교육종류2
        educationTypeCd: null,  // 전사자체/부서자체/사내위탁/사외교육/연수/기타
        legalEducationFlag: 'Y',  // 법정교육 여부
        educationTime: '',  // 교육시간
        educationCycle: null,  // 주기
        educationPurpose: '',  // 학습목적
        mainmainTargetAudience: '',  // 기술
        estimatedEducationExpenses: '',  // 예상 교육비
        relationLaws: '',  // 관련 법규
        useFlag: 'Y',
        regUserId: '',
        chkUserId: '',

        eduCourseSubjectList: [], // 과정별 교육과목
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      flagItems: [
        { code: 'Y', codeName: '해당' },
        { code: 'N', codeName: '해당없음' },
      ],
      grid: {
        columns: [
          {
            name: 'subjectName',
            field: 'subjectName',
            label: '과목 및 세부내용',
            style: 'width:200px',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'educationTime',
            field: 'educationTime',
            label: '교육시간',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:50px',
            align: 'center',
            sortable: false
          },
          {
            name: 'instructorName',
            field: 'instructorName',
            type: 'text',
            label: '강사명',
            style: 'width:100px',
            align: 'left',
            sortable: false
          },
        ],
        data: [],
        height: '355px'
      },
      editable: true,
      saveUrl: 'transactionConfig.sop.edu.course.insert.url',
      saveType: 'POST',
      isSave: false,
      checkUrl: '', 
      getUrl: '',
      insertUrl: '',
      deleteUrl: '',
      updateUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // url setting
      this.getUrl = selectConfig.sop.edu.course.get.url;
      this.checkUrl = selectConfig.sop.edu.course.check.url;
      this.insertUrl = transactionConfig.sop.edu.course.insert.url;
      this.updateUrl = transactionConfig.sop.edu.course.update.url;
      // list setting
      this.getDetail();
      // this.setHeader();
    },
    getDetail() {
      if (this.eduCourseId) {
        this.$http.url = this.$format(this.getUrl, this.eduCourseId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.educationInfo = this.$_.clone(_result.data);
          this.updateMode = true;
        },);
      }
    },
    addItem() {
      this.educationInfo.eduCourseSubjectList.splice(0, 0, {
        eduCourseId: this.eduCourseId,
        subjectNo: uid(),
        subjectName: '', // 교육명
        subjectDetailName: '', // 세부내용
        educationTime: '', // 교육시간
        instructorName: '', // 강사명
        editFlag: 'C',
      })
    },
    removeItem() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.educationInfo.eduCourseSubjectList = this.$_.reject(this.educationInfo.eduCourseSubjectList, item)
        })
      }
    },
    saveInfo() {
      if (this.eduCourseId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
        this.educationInfo.companyCd = this.$store.getters.user.companyCd;
      }
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) { 
          this.$http.url = this.checkUrl
          this.$http.type = 'GET';
          this.$http.param = {
            educationCourseName: this.educationInfo.educationCourseName,
            eduCourseId: this.eduCourseId
          }
          this.$http.request((_result) => {
            if (_result.data > 0) {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: '이미 존재하는 과정명입니다.', // 이미 존재하는 과정명입니다.
                type: 'warning', // success / info / warning / error
              });
              return;
            } else {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?',
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.educationInfo.regUserId = this.$store.getters.user.userId;
                  this.educationInfo.chgUserId = this.$store.getters.user.userId;
                
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
                });
              }
            },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.eduCourseId = result.data;
      this.$emit('changeStatus')
      this.getDetail();
    },
  }
};
</script>
